<template>
    <div>
      <CCard>
        <CCardBody>
          <div class="row justify-content-between">
            <div class="col-10">
              <div class="row mb-3">
                <button
                  class="btn btn-sm btn-primary ml-2"
                  v-if="user.role.name.toLowerCase() == 'admin'"
                  @click="addModal()"
                >
                  <CIcon name="cilMedicalCross" />
                  Tambah
                </button>
  
                <button
                  class="btn btn-sm btn-primary ml-2"
                  v-if="user.role.name.toLowerCase() == 'admin'"
                  @click="addModalImport()"
                >
                  <CIcon name="cilArrowThickToBottom" />
                  Import
                </button>
              
                <!-- <button
                    class="btn btn-sm btn-success mr-2 ml-2"
                  >
                    <CIcon name="cil-spreadsheet" />
                    Export Excel
                </button>
                <button class="btn btn-sm btn-danger">
                  <CIcon name="cib-adobe-acrobat-reader" />
                  Export PDF
                </button> -->
               
                <label class="m-1 ml-3" for="">Select All : </label>
                <input
                  type="checkbox"
                  v-model="isSelectedAll"
                  @change="checkAll()"
                />
  
                <select v-if="selectedItems.length > 0"
                    style="max-width: 200px"
                    class="form-control form-control-sm mx-2"
                    placeholder="Ketik disini"
                    v-model="selectedAction"
                    @change="changeActionSelected()"
                  >
                  <option value="0">Action Selected</option>
                  <option value="1">Delete Items Selected</option>
                  <option value="2">Export Excel Items Selected</option>
                  <option value="3">Export Pdf Items Selected</option>
                </select>
  
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-10">
              <div class="row mb-3">
                <label class="m-1 ml-3" for="">Search : </label>
                <input
                  type="text"
                  v-model="params.keyword"
                  style="max-width: 175px"
                  class="form-control form-control-sm mx-2"
                  placeholder="Ketik disini"
                />
                <button @click="search()" class="btn btn-sm btn-success">
                  Cari
                </button>
  
                <!-- <select
                    style="max-width: 150px"
                    class="form-control form-control-sm mx-2"
                    placeholder="Ketik disini"
                    v-model="params.kecamatan"
                    @change="getData"
                  >
                  <option value="">Kecamatan</option>
                  <option v-for="(item) in kecamatan" :key="item.key" :value="item.value">{{ item.key }}</option>
                </select> -->
                <v-select 
                  @input="fixKecamatanParams"
                  placeholder="Kecamatan"
                  class="mx-2" 
                  style="width: 150px"
                  :reduce="kecamatan => kecamatan.value" 
                  v-model="params.kecamatan" 
                  label="key" 
                  :options="kecamatan"></v-select>
                <!-- <select
                    style="max-width: 150px"
                    class="form-control form-control-sm mx-2"
                    placeholder="Ketik disini"
                    v-model="params.desa"
                    @change="getData"
                  >
                  <option value="">Desa</option>
                  <option v-for="item in desa" :key="item.key" :value="item.value">{{ item.key }}</option>
                </select> -->
                <v-select 
                  @input="fixDesaParams"
                  placeholder="Desa"
                  class="" 
                  style="width: 150px"
                  :reduce="desa => desa.value" 
                  v-model="params.desa" 
                  label="key" 
                  :options="desa"></v-select>
              </div>
            </div>
            <div class="col-2">
              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Per Halaman</label
                      >
                    </div>
                    <select
                      class="custom-select"
                      style="max-width: 100px"
                      id="inputGroupSelect01"
                      v-model="params.row"
                      @change="getData()"
                    >
                      <!-- <option selected>Pilih...</option> -->
                      <option selected value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="1500">1500</option>
                      <option value="2000">2000</option>
                      <option :value="total">All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="isSearching">
            <div class="col">
              <h3>
                <span class="badge bg-primary text-light text-bor my-auto">
                  {{searchOn}}&nbsp;&nbsp;
                  <span @click="searchOff" class="badge bg-light text-dark text-center" style="cursor: pointer">X</span>
                </span>
              </h3>
            </div>
          </div>
          <!-- start table -->
          <CDataTable
            class="table-striped"
            :items="computedItems"
            :fields="fields"
            sorter
          >
          <template #select="{ item }">
            <td class="py-2">
              <input 
                  type="checkbox"
                  @change="check(item)"
                  v-model="item.select"
                />
            </td>
          </template>
          <template #file_pengukuhan="{item}">
            <td v-if="item.file_pengukuhan">
              <!-- {{ item.file_pengukuhan }} -->
              <CButton class="bg-primary text-white" @click="openFile(item.file_pengukuhan)">Download File</CButton>
            </td>
            <td v-else>
              <h4 class="text-center">-</h4>
            </td>
          </template>
          <template #file_pembentukan="{item}">
            <td v-if="item.file_pembentukan">
              <!-- {{ item.file_pengukuhan }} -->
              <CButton class="bg-primary text-white" @click="openFile(item.file_pembentukan)">Download File</CButton>
            </td>
            <td v-else>
              <h4 class="text-center">-</h4>
            </td>
          </template>
          <template #file_simluhtan="{item}">
            <td v-if="item.file_simluhtan">
              <!-- {{ item.file_pengukuhan }} -->
              <CButton class="bg-primary text-white" @click="openFile(item.file_simluhtan)">Download File</CButton>
            </td>
            <td v-else>
              <h4 class="text-center">-</h4>
            </td>
          </template>
          <template #file_skt="{item}">
            <td v-if="item.file_skt">
              <!-- {{ item.file_pengukuhan }} -->
              <CButton class="bg-primary text-white" @click="openFile(item.file_skt)">Download File</CButton>
            </td>
            <td v-else>
              <h4 class="text-center">-</h4>
            </td>
          </template>
          <template #file_akta_pendirian="{item}">
            <td v-if="item.file_akta_pendirian">
              <!-- {{ item.file_pengukuhan }} -->
              <CButton class="bg-primary text-white" @click="openFile(item.file_akta_pendirian)">Download File</CButton>
            </td>
            <td v-else>
              <h4 class="text-center">-</h4>
            </td>
          </template>
          <template #action="{ item }">
            <td class="py-2">
              <CButton
                @click="edit(item)"
                class="mr-2"
                color="warning"
                square
                size="sm"
              >
                Edit
              </CButton>
              <CButton @click="hapus(item)" color="danger" square size="sm">
                Delete
              </CButton>
            </td>
          </template>
          </CDataTable>
          <pagination
            v-if="total !== items.length"
            v-model="page"
            :records="total"
            :per-page="500"
            @paginate="pagination"
          />
        </CCardBody>
      </CCard>
      <!-- Start modal -->
      <CModal
        size="lg"
        :title="isUpdate ? 'Edit Kelompok' : 'Tambah Kelompok'"
        centered
        :color="isUpdate ? 'success' : 'primary'"
        :show.sync="createModal"
      >
      <div>
        <div class="row">
          <div class="col-4">Nama Kelompok</div><div class="col-8"><CInput v-model="form.nama" placeholder="nama kelompok"/></div>
          <div class="col-4">Nama Ketua Kelompok</div><div class="col-8"><CInput v-model="form.nama_ketua_kelompok" placeholder="nama ketua"/></div>
          <div class="col-4">Jumlah</div><div class="col-8"><CInput v-model="form.jumlah_anggota" placeholder="jumlah anggota"/></div>
          <div class="col-4">Alamat</div><div class="col-8"><CInput v-model="form.alamat" placeholder="alamat"/></div>
          <div class="col-4">Kecamatan</div>
          <div class="col-4">
            <v-select 
              placeholder="Kecamatan"
              class="mb-3" 
              style="width: 200px"
              :reduce="kecamatan => kecamatan.value" 
              v-model="form.kecamatan" 
              label="key" 
              :options="kecamatan"></v-select>
          </div>
          <div class="col-4"></div>
          <div class="col-4">Desa</div>
          <div class="col-4">
            <v-select 
              placeholder="Desa"
              class="mb-3" 
              style="width: 200px"
              :reduce="desa => desa.value" 
              v-model="form.desa" 
              label="key" 
              :options="desa"></v-select>
            <!-- <select
                style="max-width: 100%"
                class="form-control form-control-md mb-3"
                v-model="form.desa"
              >
              <option value="" disabled>Desa</option>
              <option v-for="(item, i) in desa" :key="i" :value="item.value">{{ item.key }}</option>
            </select> -->
            <!-- <CSelect v-model="form.desa" v-for="(item, i) in desa" :key="i" :options="[]"/> -->
          </div>
          <div class="col-4"></div>
          <div class="col-4">Tingkat Level Kelompok</div>
          <div class="col-8">
            <div class="mb-3">
              <div class="form-check">
                <input class="form-check-input" v-model="form.level" type="radio" value="pemula" id="flexCheckPemula">
                <label class="form-check-label" for="flexCheckPemula">
                  Pemula
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" v-model="form.level" type="radio" value="lanjut" id="flexCheckLanjut">
                <label class="form-check-label" for="flexCheckLanjut">
                  Lanjut
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" v-model="form.level" type="radio" value="madya" id="flexCheckMadya">
                <label class="form-check-label" for="flexCheckMadya">
                  Madya
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" v-model="form.level" type="radio" value="utama" id="flexCheckUtama">
                <label class="form-check-label" for="flexCheckUtama">
                  Utama
                </label>
              </div>
            </div>
          </div>
          <div class="col-4">No Telepon</div><div class="col-4"><CInput v-model="form.nomor_telepon" placeholder="no telepon"/></div><div class="col-4"></div>
          <div class="col-4">Tanggal Pengukuhan</div><div class="col-4"><CInput type="date" v-model="form.tanggal_pengukuhan" placeholder="tanggal pengukuhan"/></div><div class="col-4"></div>
          <div class="col-4">No Pengukuhan</div><div class="col-4"><CInput v-model="form.no_pengukuhan" placeholder="no pengukuhan"/></div><div class="col-4"></div>
          <div class="col-4">File Pengukuhan</div>
          <div class="col-8 mb-3">
            <div class="row">
              <div class="col text-center">
                <label for=""></label>
                <div
                  v-if="form.file_pengukuhan === ''"
                  class="card border d-flex justify-content-center"
                  style="height: 250px"
                >
                  <div class="align-self-center mb-3">
                    <img src="@/assets/upload.png" width="100px" alt="" />
                  </div>
                  <button
                    class="btn btn-sm btn-primary align-self-center"
                    style="width: 50%"
                    @click="uploadBefore"
                  >
                    Upload Disini
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="div">
                  <input
                    hidden
                    type="file"
                    class="form-control"
                    ref="uploadFieldBefore"
                    @change="selectFileBefore"
                  />
                </div>
              </div>
            </div>
            <div class="row" v-if="form.file_pengukuhan">
              <div class="col-md-3">
                <button
                  class="btn btn-primary btn-sm btn-block mt-2"
                  @click="openFile(form.file_pengukuhan)"
                >
                  Preview
                </button>
                <button
                  class="btn btn-danger btn-sm btn-block mt-2"
                  @click="deleteImageBefore"
                >
                  Hapus
                </button>
                <br />
              </div>
            </div>
          </div>
          <div class="col-4">Tanggal Pembentukan</div><div class="col-4"><CInput type="date" v-model="form.tanggal_pembentukan" placeholder="tanggal pembentukan"/></div><div class="col-4"></div>
          <div class="col-4">No Pembentukan</div><div class="col-4"><CInput v-model="form.no_pembentukan" placeholder="no pembentukan"/></div><div class="col-4"></div>
          <div class="col-4">File Pembentukan</div>
          <div class="col-8 mb-3">
            <!-- file pembentukan -->
            <div class="row">
              <div class="col text-center">
                <label for=""></label>
                <div
                  v-if="form.file_pembentukan === ''"
                  class="card border d-flex justify-content-center"
                  style="height: 250px"
                >
                  <div class="align-self-center mb-3">
                    <img src="@/assets/upload.png" width="100px" alt="" />
                  </div>
                  <button
                    class="btn btn-sm btn-primary align-self-center"
                    style="width: 50%"
                    @click="uploadAfter"
                  >
                    Upload Disini
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="div">
                  <input
                    hidden
                    type="file"
                    class="form-control"
                    ref="uploadFieldAfter"
                    @change="selectFileAfter"
                  />
                </div>
              </div>
            </div>
            <div class="row" v-if="form.file_pembentukan">
              <div class="col-md-3">
                <button
                  class="btn btn-primary btn-sm btn-block mt-2"
                  @click="openFile(form.file_pembentukan)"
                >
                  Preview
                </button>
                <button
                  class="btn btn-danger btn-sm btn-block mt-2"
                  @click="deleteImageAfter"
                >
                  Hapus
                </button>
                <br />
              </div>
            </div>
          </div>
          <div class="col-12">
            <p class="text-bold">Perijinan</p>
          </div>
          <div class="col-12">
            <table class="tbl">
              <tr>
                <th class="th-tbl">No</th>
                <th class="th-tbl">Jenis File</th>
                <th class="th-tbl">File</th>
              </tr>
              <tr>
                <td class="td-tbl">1</td>
                <td class="td-tbl">Simluhtan</td>
                <td class="td-tbl">
                  <div class="row">
                    <div class="col text-center">
                      <label for=""></label>
                      <div
                        v-if="form.file_simluhtan === ''"
                        class="card border d-flex justify-content-center"
                        style="height: 250px"
                      >
                        <div class="align-self-center mb-3">
                          <img src="@/assets/upload.png" width="100px" alt="" />
                        </div>
                        <button
                          class="btn btn-sm btn-primary align-self-center"
                          style="width: 50%"
                          @click="uploadProcess"
                        >
                          Upload Disini
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="div">
                        <input
                          hidden
                          type="file"
                          class="form-control"
                          ref="uploadFieldProcess"
                          @change="selectFileProcess"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12" v-if="form.file_simluhtan">
                      <button
                        class="btn btn-primary btn-sm btn-block mt-2"
                        @click="openFile(form.file_simluhtan)"
                      >
                        Preview
                      </button>
                      <button
                        class="btn btn-danger btn-sm btn-block mt-2"
                        @click="deleteImageProcess"
                      >
                        Hapus
                      </button>
                      <br />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="td-tbl">2</td>
                <td class="td-tbl">SKT</td>
                <td class="td-tbl">
                  <div class="row">
                    <div class="col text-center">
                      <label for=""></label>
                      <div
                        v-if="form.file_skt === ''"
                        class="card border d-flex justify-content-center"
                        style="height: 250px"
                      >
                        <div class="align-self-center mb-3">
                          <img src="@/assets/upload.png" width="100px" alt="" />
                        </div>
                        <button
                          class="btn btn-sm btn-primary align-self-center"
                          style="width: 50%"
                          @click="uploadSkt"
                        >
                          Upload Disini
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="div">
                        <input
                          hidden
                          type="file"
                          class="form-control"
                          ref="uploadFieldSkt"
                          @change="selectFileSkt"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" >
                    <div class="col-md-12" v-if="form.file_skt">
                      <button
                        class="btn btn-primary btn-sm btn-block mt-2"
                        @click="openFile(form.file_skt)"
                      >
                        Preview
                      </button>
                      <button
                        class="btn btn-danger btn-sm btn-block mt-2"
                        @click="deleteImageSkt"
                      >
                        Hapus
                      </button>
                      <br />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="td-tbl">3</td>
                <td class="td-tbl">Akta Pendirian ( Kemenkumham )</td>
                <td class="td-tbl">
                  <div class="row">
                    <div class="col text-center">
                      <label for=""></label>
                      <div
                        v-if="form.file_akta_pendirian === ''"
                        class="card border d-flex justify-content-center"
                        style="height: 250px"
                      >
                        <div class="align-self-center mb-3">
                          <img src="@/assets/upload.png" width="100px" alt="" />
                        </div>
                        <button
                          class="btn btn-sm btn-primary align-self-center"
                          style="width: 50%"
                          @click="uploadAktaPendirian"
                        >
                          Upload Disini
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="div">
                        <input
                          hidden
                          type="file"
                          class="form-control"
                          ref="uploadFieldAktaPendirian"
                          @change="selectFileAktaPendirian"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12" v-if="form.file_akta_pendirian">
                      <button
                        class="btn btn-primary btn-sm btn-block mt-2"
                        @click="openFile(form.file_akta_pendirian)"
                      >
                        Preview
                      </button>
                      <button
                        class="btn btn-danger btn-sm btn-block mt-2"
                        @click="deleteImageAktaPendirian"
                      >
                        Hapus
                      </button>
                      <br />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="cancel" class="btn btn-secondary mr-3">Batal</button>

          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update 
          </button>
        </div>
      </template>
      </CModal>
      <!-- Import modal -->
      <CModal
          size="lg"
          title="Import Data"
          centered
          color="primary"
          :show.sync="createModalImport"
        >
        <div class="row">
          <div class="col">
            <a href="kelompok.xlsx" download class="btn btn-primary mb-3">Download format</a>
            <div class="div">
              <input
                type="file"
                class="form-control"
                ref="importData"
                @change="importData"
              />
            </div>
          </div>
        </div>
        <template slot="footer">
        <div>
          <button @click="cancelImport" class="btn btn-secondary mr-3">Batal</button>

          <button @click="submitImport" class="btn btn-primary">
            Import
          </button>
        </div>
      </template>
      </CModal>
    </div>
  </template>
  
  
  
  <script>
  import * as data from "../../model/list-kelompok";
  import { uploadImage } from "@/utils/fileUpload";
  import kecamatan, { desa } from "@/utils/internalData";
  import FileSaver from "file-saver";

  export default {
    data() {
      return {
        kecamatan: kecamatan,
        desa: desa,
        selectedKecamatan:"",
        selectedDesa:"",
        selectedJenisHewan: 0,
        createModal: false,
        createModalImport: false,
        modalFields: [],
        fields: [],
        isUpdate: false,
        items: [],
        imageListAfter: [],
        imageListProcess: [],
        imageListBefore: [],
        imageListSimhultan: [],
        imageListSkt: [],
        imageListAktaPendirian: [],
        selectedItems: [],
        isSelectedAll: false,
        selectedAction: 0,
        jenisHewan: [],
        page: 1,
        total: 0,
        selectedModalDesa:"",
        form: {
          desa:"",
          kecamatan: "",
          nama:"",
          jumlah_anggota: "",
          level:"",
          alamat: "",
          nomor_telepon: "",
          nama_ketua_kelompok: "",
          tanggal_pembentukan:"",
          tanggal_pengukuhan:"",
          no_pembentukan:"",
          no_pengukuhan: "",
          file_pengukuhan:'',
          file_pembentukan:'',
          file_simluhtan: '',
          file_skt: '',
          file_akta_pendirian: '',
          user_id: "",
        },
        user: JSON.parse(localStorage.getItem("user")),
        params: {
          sorttype: "desc",
          sortby: "id",
          row: 500,
          page: 1,
          type: [0, 1],
          keyword: "",
          kecamatan: "",
          desa: ""
        },
        isSearching: false,
        searchOn: ''
      };
    },
    methods: {
      cancelImport(){
        this.file = ''
        this.createModalImport = false
      },
      submitImport(){
        let formData = new FormData()
        formData.append('file', this.file)
        var loading = this.$loading.show();
        this.$store
          .dispatch('list_kelompok/importFile', formData)
          .then((resp) => {
            this.$toast.success("Berhasil import data");
            this.createModalImport = false
            loading.hide();
            this.file = ""
            this.getData()
            console.log(resp)
          })
          .catch(err => console.log(err))
      },
      fixKecamatanParams(val){
        val === null ? this.params.kecamatan = "" : this.params.kecamatan = val
        this.getData()
      },
      fixDesaParams(val){
        val === null ? this.params.desa = "" : this.params.desa = val
        this.getData()
      },
      getJenisHewan() {
        var loading = this.$loading.show();
        this.$store
          .dispatch("jenis_hewan/getJenisHewan", this.params)
          .then((resp) => {
            this.jenisHewan = resp.data.data;
  
            // khusus untuk checkbox
            this.selectedItems = [];
            this.items.forEach(element => {
              if (this.isSelectedAll) {
                element.select = true;
                this.selectedItems.push(element.id);
              } else {
                element.select = false;
              }
            });
            loading.hide();
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      openFile(url){
        window.open(url)
        console.log(url)
      },
      search() {
        if (this.params.keyword != "") {
          this.isSearching = true;
          this.getData();
          this.searchOn = this.params.keyword;
          // this.params.keyword = '';
        } else {
          this.$toast.error("Inputan tidak boleh kosong !!");
        }
      },
  
      searchOff(){
        this.isSearching = false;
        this.params.keyword = '';
        this.getData();
      },
      submit() {
        this.form.tanggal_pengukuhan = this.$moment(this.form.tanggal_pengukuhan).format("YYYY-MM-DD");
        this.form.tanggal_pembentukan = this.$moment(this.form.tanggal_pembentukan).format("YYYY-MM-DD");
        this.form.jumlah_anggota = +this.form.jumlah_anggota
  
        this.form.user_id = JSON.parse(localStorage.getItem("user")).id;
        var loading = this.$loading.show();
        this.$store
          .dispatch("list_kelompok/addListKelompok", this.form)
          .then(() => {
            this.$toast.success("Berhasil menambahkan data");
            loading.hide();
            this.createModal = false;
            this.form = {
            };
            this.getData();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
            console.log(e)
          });
      },
      edit(item) {
        this.form = item;
        this.isUpdate = true;
        this.createModal = true;
        this.form.tanggal_pengukuhan = this.$moment(this.form.tanggal_pengukuhan).format("YYYY-MM-DD");
        this.form.tanggal_pembentukan = this.$moment(this.form.tanggal_pembentukan).format("YYYY-MM-DD");

        this.form.file_pengukuhan = item.file_pengukuhan
        this.form.file_pembentukan = item.file_pembentukan
        this.form.file_simluhtan = item.file_simluhtan
        this.form.file_skt = item.file_skt
        this.form.file_akta_pendirian = item.file_akta_pendirian

      },
      cancel() {
        this.form = {
          desa:"",
          kecamatan: "",
          nama:"",
          jumlah_anggota: "",
          level:"",
          alamat: "",
          nomor_telepon: "",
          ketua_kelompok: "",
          tanggal_pembentukan:"",
          tanggal_pengukuhan:"",
          no_pembentukan:"",
          file_pengukuhan:"",
          file_pembentukan:"",
          file_simluhtan: "",
          file_skt: "",
          file_akta_pendirian: "",
          user_id: "",
        }
        this.createModal = false;
      },
      update() {
        var loading = this.$loading.show();
        delete this.form.updated_at;

        
        this.form.user_id = JSON.parse(localStorage.getItem("user")).id;
        this.form.jumlah_anggota = +this.form.jumlah_anggota
        this.$store
          .dispatch("list_kelompok/updateListKelompok", {
            id: this.form.id,
            data: this.form,
          })
          .then(() => {
            this.$toast.success("Berhasil merubah data ");
            loading.hide();
            this.createModal = false;
            this.form = {
              desa:"",
              kecamatan: "",
              nama:"",
              jumlah_anggota: "",
              level:"",
              alamat: "",
              ketua_kelompok: "",
              nomor_telepon: "",
              tanggal_pembentukan:"",
              tanggal_pengukuhan:"",
              no_pembentukan:"",
              file_pengukuhan:"",
              file_pembentukan:"",
              file_simluhtan: "",
              file_skt: "",
              file_akta_pendirian: "",
              user_id: "",
            }
            this.getData();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      },
      hapus(item) {
        if (confirm("Data akan dihapus !!")) {
          this.$store
            .dispatch("list_kelompok/deleteListKelompok", item.id)
            .then(() => {
              this.$toast.success("Berhasil menghapus data ");
  
              this.form = {
                type: 0,
              };
              this.getData();
            })
            .catch((e) => {
              this.$toast.error(e);
              this.$loading.hide();
            });
        }
      },
      getData() {
        var loading = this.$loading.show();
        this.params.kecamatan === null ? this.params.kecamatan : ""
        this.params.desa === null ? this.params.desa : ""
        this.items = []
        this.$store
          .dispatch("list_kelompok/getListKelompok", this.params)
          .then((resp) => {
            this.items = resp.data.data;
            this.total = resp.data.total;
  
            // khusus untuk checkbox
            this.selectedItems = [];
            this.items.forEach(element => {
              if (this.isSelectedAll) {
                element.select = true;
                this.selectedItems.push(element.id);
              } else {
                element.select = false;
              }
            });
            loading.hide();
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      addModal() {
        this.isUpdate = false;
        this.createModal = true;
      },
      addModalImport() {
        console.log("awkwkkw");
        this.createModalImport = true;
      },
      pagination(page) {
        this.page = page;
        this.params.page = page;
        this.getData();
      },
      uploadAfter() {
        this.$refs.uploadFieldAfter.click();
      },
      selectFileAfter(event) {
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadImage(this.file)
          .then((resp) => {
            this.form.file_pembentukan = resp
            loading.hide();
            alert("File berhasil diupload !!");
          })
          .catch((e) => {
            loading.hide();
            alert("Terjadi kesalahan !! | " + e);
          });
      },
      uploadProcess() {
        this.$refs.uploadFieldProcess.click();
        
      },
      selectFileProcess(event) {
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadImage(this.file)
          .then((resp) => {
            this.form.file_simluhtan = resp
            loading.hide();
            alert("File berhasil diupload !!");
          })
          .catch((e) => {
            loading.hide();
            alert("Terjadi kesalahan !! | " + e);
          });
      },
      uploadBefore() {
        this.$refs.uploadFieldBefore.click();
      },
      selectFileBefore(event) {
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadImage(this.file)
          .then((resp) => {
            // console.log(resp)
            // this.imageListBefore.push(resp);
            this.form.file_pengukuhan = resp
            loading.hide();
            alert("File berhasil diupload !!");
            console.log(this.imageListBefore[0])
          })
          .catch((e) => {
            loading.hide();
            alert("Terjadi kesalahan !! | " + e);
          });
      },
      uploadSkt() {
        this.$refs.uploadFieldSkt.click();
      },
      selectFileSkt(event) {
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadImage(this.file)
          .then((resp) => {
            this.form.file_skt = resp
            loading.hide();
            alert("File berhasil diupload !!");
          })
          .catch((e) => {
            loading.hide();
            alert("Terjadi kesalahan !! | " + e);
          });
      },
      uploadAktaPendirian() {
        this.$refs.uploadFieldAktaPendirian.click();
      },
      selectFileAktaPendirian(event) {
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadImage(this.file)
          .then((resp) => {
            this.form.file_akta_pendirian = resp
            loading.hide();
            alert("File berhasil diupload !!");
          })
          .catch((e) => {
            loading.hide();
            alert("Terjadi kesalahan !! | " + e);
          });
      },
      deleteImageBefore() {
        this.form.file_pengukuhan = '';
      },
      deleteImageProcess() {
        this.form.file_simluhtan = '';
      },
      deleteImageAfter() {
        this.form.file_pembentukan = '';
      },
      deleteImageSkt() {
        this.form.file_skt = '';
      },
      deleteImageAktaPendirian() {
        this.form.file_akta_pendirian = '';
      },
      extractGallery(item) {
        let data = [];
        item.forEach(element => {
          data.push(element.image);
        });
        return data;
      },
      check(item) {
        if (item.select) {
          this.selectedItems.push(item.id);
        } else {
          const index = this.selectedItems.indexOf(item.id);
          this.selectedItems.splice(index, 1);
        }
      },
      checkAll() {
        this.getData();
      },
      importData($event) {
        console.log($event, "event");
        this.file = event.target.files[0];
        // this.$loading.show();
        
      },
      changeActionSelected() {
        console.log(this.selectedAction)
        switch (Number(this.selectedAction)) {
          case 1:
            console.log('deleted')
            this.deleteSelected('delete');
            break;
          case 2:
            console.log('export excel')
            this.exportExcel('export_excel');
            break;
          case 3:
            console.log('export pdf')
            this.exportPDF();
            break;
        }
      },
      deleteSelected(action) {
        var loading = this.$loading.show();
        this.$store
          .dispatch("list_kelompok/selectedAction", 
          {
            action: action,
            data: this.selectedItems,
          })
          .then(() => {
            this.$toast.success("Item Selected Berhasil diproses");
            loading.hide();
            this.createModal = false;
            this.form = {
            };
            this.getData();
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      openModalExcel() {
        this.exportModal = true;
        this.exportType = "Export Excel";
      },
      openModalPDF() {
        this.exportModal = true;
        this.exportType = "Export PDF";
      },
      exportExcel() {
        var loading = this.$loading.show();
        this.$store
         .dispatch("list_kelompok/exportReport", {
            data: this.selectedItems,
          })
          .then((resp) => {
            loading.hide();
            console.log(resp.data)
            FileSaver.saveAs(
              resp.data,
              "Laporan_Harian_"
            );
            this.exportModal = false;
            this.exportDataParams = {};
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      },
      exportPDF() {
        this.$router.push({ name: "ExportKelompokPdf", query: { data: this.selectedItems } });
      },
    },
    computed: {
      computedItems() {  
        return this.items.map((item, index) => {
          return {
            index: index+1 + '.',
            ...item,
            // nilai_kontrak: item.nilai_kontrak.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            // full_name: item.user != null ? item.user.full_name : "",
            // opd: item.role != null ? item.role.name : "",
            // created_at: item.created_at.slice(0, 10),
            tanggal_pengukuhan: this.$moment(item.tanggal_pengukuhan).format("Do MMMM YYYY"),
            tanggal_pembentukan: this.$moment(item.tanggal_pembentukan).format("Do MMMM YYYY"),
          };
        });
      },
    },
    mounted() {
      this.getData();
      this.getJenisHewan()
    },
  
    created() {
      this.fields = this.user.role.name.toLowerCase() == "admin" ? data.fields : data.fields_no_action;
    },
  };
</script>

<style scoped>
.text-bold {
  font-weight: bold;
}
.tbl, .th-bl, .td-tbl{
  border: 1px solid;
  align-items: center;
}
.th-tbl,.td-tbl {
  padding: 5px 8px;
}
.tbl {
  width: 100%;
}
</style>
