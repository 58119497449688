const fields = [
    { key: 'index', label: "No", _style:'min-width:50px'},
    { key: 'select', label: "select", _style:'min-width:50px' },
    { key: 'nama', label: "Nama Kelompok", _style:'min-width:150px;' },
    { key: 'nama_ketua_kelompok', label: "Nama Ketua Kelompok", _style:'min-width:150px;' },
    { key: 'nomor_telepon', label: "No Telepon", _style:'min-width:150px;' },
    { key: 'alamat', label: "Alamat", _style:'min-width:150px;' },
    { key: "kecamatan", label: "Kecamatan", _style:'min-width:150px'},
    { key: 'desa', label: "Desa", _style:'min-width:150px;' },
    { key: 'jumlah_anggota', label: "Jumlah Anggota", _style:'min-width:100px;' },
    { key: 'level', label: "Level", _style:'min-width:100px' },
    { key: 'tanggal_pengukuhan', label: "Tanggal Pengukuhan", _style:'min-width:150px' },
    { key: 'no_pengukuhan', label: "No Pengukuhan", _style:'min-width:150px' },
    { key: 'file_pengukuhan', label: "File Pengukuhan", _style:'min-width:150px' },
    { key: 'tanggal_pembentukan',label: "Tanggal Pembentukan", _style:'min-width:150px;' },
    { key: 'no_pembentukan',label: "No Pembentukan", _style:'min-width:150px;' },
    { key: 'file_pembentukan',label: "File Pembentukan", _style:'min-width:150px;' },
    { key: 'file_simluhtan',label: "Perizinan Simluhtan", _style:'min-width:150px;' },
    { key: 'file_skt',label: "Perizinan SKT", _style:'min-width:150px;' },
    { key: 'file_akta_pendirian',label: "Perizinan Akta Pendirian ( Kemenkumham )", _style:'min-width:250px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:150px'}
]


export { fields }